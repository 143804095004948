import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Wrapper from '../components/Wrapper'
import { DOWNLOADER, CONTACT_US, PREMIUM } from '../data/constants'
import SEO from '../components/SEO'

/* eslint-disable */
class PaymentStatus extends React.Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
  }

  state = {
    status: 'unknown',
  }

  componentDidMount() {
    const {
      location: { search },
    } = this.props
    const [, status] = search.split('?status=')
    // eslint-disable-next-line react/no-did-mount-set-state
    this.setState({
      status: status || 'unknown',
    })
  }

  render() {
    const { status } = this.state
    return (
      <>
        <SEO page="paymentStatus" />
        <Wrapper title="Payment Status">
          <div className="col-sm-6">
            <div className="cboxEraForm text-center mt-4">
              {/* eslint-disable */}
              {status === 'success' && (
                <span>
                  <i
                    className="far fa-check-circle fa-4x mb-2 text-success"
                    aria-hidden="true"
                  />
                  <p className="mt-4 mb-1 font-weight-bold">Success !</p>
                  <p className="mt-2 mb-1">Your Payment Successful</p>
                  <p className="mt-2">
                    Go to{' '}
                    <Link
                      className="text-danger font-weight-bold"
                      to={DOWNLOADER}
                    >
                      Downloader
                    </Link>
                  </p>
                </span>
              )}
              {status === 'failed' && (
                <span>
                  <i
                    className="far fa-times-circle fa-4x mb-1 text-danger"
                    aria-hidden="true"
                  />
                  <p className="mt-4 mb-1 font-weight-bold">Failed</p>
                  <p className="mt-2 mb-1">
                    Sorry, Your Payment is not successful
                  </p>
                  <p className="mt-2">
                    Go to{' '}
                    <Link className="text-danger font-weight-bold" to={PREMIUM}>
                      Premium Page
                    </Link>{' '}
                    and try again.
                  </p>
                </span>
              )}
              {status === 'unknown' && (
                <span>
                  <i
                    className="fas fa-exclamation-circle fa-4x mb-2 text-danger"
                    aria-hidden="true"
                  />
                  <p className="mt-4 mb-1 font-weight-bold">
                    Oops..! Something went wrong!
                  </p>
                  <p className="mt-2">
                    Please{' '}
                    <Link
                      to={CONTACT_US}
                      className="text-danger font-weight-bold"
                    >
                      Contact us
                    </Link>
                  </p>
                </span>
              )}
            </div>
            {/* eslint-enable */}
          </div>
        </Wrapper>
      </>
    )
  }
}

export default PaymentStatus
